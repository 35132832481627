import React, { Fragment } from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import { AuthUserContext, withAuthorization } from "../components/Session"
import PageTitleArea from "../components/Common/PageTitleArea"
import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"

function MyLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(255, 255, 255, 0.5)",
        }),
        spinner: base => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(199, 59, 27, 1)",
          },
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  )
}

class AccountPageBase extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  handleSubmit = user => async event => {
    event.preventDefault()
    let that = this
    let english = "hasOldHassEnglish" in user ? user.hasOldHassEnglish : null
    let metric = "hasOldHassMetric" in user ? user.hasOldHassMetric : null
    let spanish = "hasOldHassSpanish" in user ? user.hasOldHassSpanish : null
    const customerInfo = {
      numUsers: event.target.numUsers.value,
      id: user.uid,
      english: english,
      metric: metric,
      spanish: spanish,
    }
    this.setState({ loading: true })

    fetch("/api/generateOldLicenses", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerInfo: customerInfo,
      }),
    }).then(function (result) {
      // Handle server response (see Step 4)
      result.json().then(function (json) {
        that.handleServerResponse(json)
      })
    })
    return false
  }

  handleServerResponse(response) {
    if (response.error) {
      // Show error from server on payment form
      alert(response.error.toString())
    } else {
      // success!
      // Show success message
      navigate("/account")
    }
  }

  render() {
    let that = this
    function LicenseComponent(props) {
      if (props.user.canDownload88) {
        return (
          <div className="row">
            <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
              <h5 className="card-title">HASS 8.8 Download</h5>
              <p>
                If you wish to stick with the old version of HASS you may
                generate your licenses here:
              </p>
              <MyLoader active={that.state.loading}>
                <form onSubmit={that.handleSubmit(props.user)}>
                  <div className="row">
                    <div className="col-lg-3 col-md-3" />
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor={"numUsers"}>Number of users:</label>
                        <select
                          id="numUsers"
                          name="numUsers"
                          className="form-control"
                          required
                        >
                          <option value="" disabled hidden>
                            Number of Users
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 text-center">
                      <button type="submit" className="default-btn">
                        <i className="bx bxs-arrow-to-right align-left"></i>
                        Generate 8.8 Licenses
                        <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              </MyLoader>
            </div>
          </div>
        )
      }
      return (
        <div className="row">
          <p>
            You do not have access to generate 8.8 licenses. Please reach out to
            hass@hrssystems.com if you believe you are seeing this message in
            error.
          </p>
        </div>
      )
    }

    return (
      <Fragment>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              <LicenseComponent user={authUser} />
            </div>
          )}
        </AuthUserContext.Consumer>
      </Fragment>
    )
  }
}

const condition = authUser => !!authUser

const AccountPage = compose(withAuthorization(condition, "account"))(
  AccountPageBase
)

export default () => (
  <Layout>
    <PageTitleArea
      pageTitle="Generate 8.8 Licenses"
    // pageDescription="High value, higher power."
    />
    <section className="free-trial-area pb-100">
      <div className="container">
        <div className="free-trial-content">
          <AccountPage />
        </div>
      </div>
    </section>
  </Layout>
)
